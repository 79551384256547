import { gleapToken } from '../api/gleapApi';
import { handleErrors } from '../utils/errorUtils';
import { doAuthQuery } from '../utils/fetch';

export const fetchGleapToken = async () => {
  try {
    const response = await doAuthQuery(gleapToken);
    if (!response.ok) {
      handleErrors(response);
      return;
    }
    let data = await response.json();
    return data.data.gleapToken;
  } catch (err) {
    console.log('Error Getting Gleap Token', err);
  }
};
