export default {
  ABS_FAULTS_FILTER: {
    active: false,
    type: {
      id: 1,
      name: 'absFault',
      key: 'ABS Faults',
      title: 'hasActiveAbsFaults',
      icon: 'absIcon',
      fieldName: 'abs_fault',
    },
  },
  TIS_FAULTS_FILTER: {
    active: false,
    type: {
      id: 2,
      name: 'tis',
      key: 'TIS Faults',
      title: 'trailerTisNotStable',
      icon: 'tisIcon',
      fieldName: 'tire_inflation_status',
    },
  },
};
