import React, { Component } from 'react';
import Modal from 'react-modal';
import Routes from './routes';
import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import './theme/css';

class App extends Component {
  componentDidMount() {
    Modal.setAppElement(document.body);
  }

  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}
if (window.Cypress) {
  window.store = store;
}

export default App;
