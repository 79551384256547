import { RECEIVE_HEADER_DATA, TOGGLE_HEADER_LOADING, FILTER_MAINTENANCE_DATA } from '../constants';
import _isEmpty from 'lodash/isEmpty';
import { ALERT_TYPES } from '../constants/enums/maintenanceDashboardEnum';
import { trailerUnitIdOrVinMatches } from '../utils/maintenanceDashboardUtils';

const initialState = {
  headerData: null,
  isHeaderLoading: false,
  lastRefreshedAt: null,
  uniqueTrailers: null,
  filteredTrailers: null,
  totalTrailerNum: 0,
};

const actionMappings = {
  [RECEIVE_HEADER_DATA]: '_receiveMaintenanceHeaderData',
  [FILTER_MAINTENANCE_DATA]: '_filterMaintenanceData',
  [TOGGLE_HEADER_LOADING]: '_toggleHeaderLoading',
};

const filterData = (trailers, filters) => {
  let filteredTrailers = trailers;

  if (!_isEmpty(filters?.selectedAlertType)) {
    if (filters?.selectedAlertType?.some(item => item.name === ALERT_TYPES.CAUTION.name)) {
      filteredTrailers = filteredTrailers.filter(trailer => trailer?.alertTypeStatusCaution);
    }
    if (filters?.selectedAlertType?.some(item => item.name === ALERT_TYPES.CRITICAL.name)) {
      filteredTrailers = filteredTrailers.filter(trailer => trailer?.alertTypeStatusCritical);
    }
    if (filters?.selectedAlertType?.some(item => item.name === ALERT_TYPES.NOT_REPORTING.name)) {
      filteredTrailers = filteredTrailers.filter(trailer => trailer?.alertTypeStatusNotReporting);
    }
  }

  if (!_isEmpty(filters?.selectedGeofence)) {
    filteredTrailers = filteredTrailers.filter(trailer => filters?.selectedGeofence?.some(item => item.name === trailer?.geofence));
  }

  if (!_isEmpty(filters?.selectedGroups)) {
    filteredTrailers = filteredTrailers.filter(trailer => filters?.selectedGroups?.some(item => trailer?.groups?.split(',').includes(item?.name)));
  }
  if (!_isEmpty(filters?.selectedCustomer)) {
    filteredTrailers = filteredTrailers.filter(trailer => trailer?.customerId?.toString() === filters?.selectedCustomer?.id);
  }

  if (filters?.searchKeyword) {
    const keywordUppercase = filters?.searchKeyword.toUpperCase();
    filteredTrailers = filteredTrailers.filter(trailer => trailerUnitIdOrVinMatches(trailer, keywordUppercase));
  }

  return filteredTrailers;
};

const reducer = {
  _receiveMaintenanceHeaderData(state, { payload }) {
    return {
      ...state,
      headerData: payload.headerData,
      lastRefreshedAt: payload.lastRefreshedAt,
      uniqueTrailers: payload.uniqueTrailers,
      filteredTrailers: payload.uniqueTrailers,
      totalTrailerNum: payload.totalTrailerNum,
    };
  },
  _filterMaintenanceData(state, { payload }) {
    return {
      ...state,
      filteredTrailers: filterData(state.uniqueTrailers, payload),
    };
  },
  _toggleHeaderLoading(state) {
    return {
      ...state,
      isHeaderLoading: !state.isHeaderLoading,
    };
  },
};

const maintenance = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default maintenance;
