export const PAGES = {
  TRACKING: {
    key: 'TRACKING',
    path: '/tracking',
    title: 'Tracking',
    isPrivateRoute: true,
  },
  INSIGHTS: {
    key: 'INSIGHTS',
    path: '/insightsnew',
    title: 'Insights',
    isPrivateRoute: true,
  },
  FLEET_STATUS: {
    key: 'FLEET_STATUS',
    path: '/fleet/currentFleetStatus',
    title: 'Fleet Status',
    isPrivateRoute: true,
  },
  MAINTENANCE: {
    key: 'MAINTENANCE',
    path: '/maintenance',
    title: 'Maintenance',
    isPrivateRoute: true,
  },
  ALERTS: {
    key: 'ALERTS',
    path: '/alertconfiguration',
    title: 'Alerts',
    isPrivateRoute: true,
  },
  PARTS: {
    key: 'PARTS',
    path: '/parts/',
    title: 'Parts',
    isPrivateRoute: true,
  },
  HELP: {
    key: 'HELP',
    path: '/help/',
    title: 'Help',
    isPrivateRoute: true,
  },
  SPECS: {
    key: 'SPECS',
    path: '/specs/',
    title: 'Specs',
    isPrivateRoute: true,
  },
  USER_MANAGEMENT: {
    key: 'USER_MANAGEMENT',
    path: '/user-management',
    title: 'User Management',
    isPrivateRoute: true,
  },
  TRAILER_MANAGEMENT: {
    key: 'TRAILER_MANAGEMENT',
    path: '/trailer-management',
    title: 'Trailer Management',
    isPrivateRoute: true,
  },
  GROUP_MANAGEMENT: {
    key: 'GROUP_MANAGEMENT',
    path: '/group-management',
    title: 'Group Management',
    isPrivateRoute: true,
  },
  CUSTOMER_MANAGEMENT: {
    key: 'CUSTOMER_MANAGEMENT',
    path: '/customer-management',
    title: 'Customer Management',
    isPrivateRoute: true,
  },
  OTA_MANAGEMENT: {
    key: 'OTA_MANAGEMENT',
    path: '/ota-management',
    title: 'OTA Management',
    isPrivateRoute: true,
  },
  ABS_TROUBLESHOOTING: {
    key: 'ABS_TROUBLESHOOTING',
    path: '/absTroubleshooting/',
    title: 'ABS Troubleshooting',
    isPrivateRoute: true,
  },
  EMAIL_VERIFICATION: {
    key: 'EMAIL_VERIFICATION',
    path: '/emailverification',
    title: 'Email Verification',
    isPrivateRoute: false,
  },
  FORGOT_PASSWORD: {
    key: 'FORGOT_PASSWORD',
    path: '/forgotPassword',
    title: 'Forgot Password',
    isPrivateRoute: false,
  },
  RESET_PASSWORD: {
    key: 'RESET_PASSWORD',
    path: '/resetPassword',
    title: 'Reset Password',
    isPrivateRoute: false,
  },
  UNSUBSCRIBE: {
    key: 'UNSUBSCRIBE',
    path: '/unsubscribe',
    title: 'Unsubscribe',
    isPrivateRoute: false,
  },
  SET_PASSWORD: {
    key: 'SET_PASSWORD',
    path: '/setpassword',
    title: 'Set Password',
    isPrivateRoute: false,
  },
  CREATE_TECHNICIAN_ACCOUNT: {
    key: 'CREATE_TECHNICIAN_ACCOUNT',
    path: '/createTechnicianAccount',
    title: 'Create Technician Account',
    isPrivateRoute: false,
  },
};

export const findPageTitleByPathname = pathname => {
  const page = Object.values(PAGES).find(it => pathname?.includes(it?.path));
  return page?.title ?? null;
};
