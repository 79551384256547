import {
  TOGGLE_DASHBOARD_FILTERS,
  SELECT_ABS_NO_FAULTS_FILTER,
  SELECT_ABS_FAULTS_FILTER,
  SELECT_ABS_ALERTS_FILTER,
  SELECT_TIS_NO_FAULTS_FILTER,
  SELECT_TIS_FAULTS_FILTER,
  SELECT_TIS_ALERTS_FILTER,
  SELECT_BATTERY_NO_FAULTS_FILTER,
  SELECT_BATTERY_FAULTS_FILTER,
  SELECT_BATTERY_ALERTS_FILTER,
  SELECT_TETHER_STATUS,
  SELECT_LOAD_STATUS,
  SELECT_PRODUCT_TYPE_STATUSES,
  SELECT_ITEM_TYPE_STATUSES,
  SELECT_CONNECTIVITY_STATUS,
  CLEAR_ALL_FILTERS,
  SET_FAULT_TOTALS,
  SET_SELECTED_FILTERS,
  SET_SELECTED_CUSTOMER,
  SET_SELECTED_GROUPS,
  SET_DASHBOARD_FILTERS_STATUS,
  GET_FILTER_COOKIE,
} from '../constants';
import { COUNTED_FILTERS, FILTER_ICON_TITLE } from '../constants/enums/filters';

const initialState = {
  expanded: false,
  absCountsFilters: {
    iconTitle: FILTER_ICON_TITLE.ABS,
    noFaults: {
      count: 0,
      selected: false,
    },
    faults: {
      count: 0,
      selected: false,
    },
    alerts: {
      count: 0,
      selected: false,
    },
  },
  tisCountsFilters: {
    iconTitle: FILTER_ICON_TITLE.TIS,
    noFaults: {
      count: 0,
      selected: false,
    },
    faults: {
      count: 0,
      selected: false,
    },
    alerts: {
      count: 0,
      selected: false,
    },
  },
  batteryCountsFilters: {
    iconTitle: FILTER_ICON_TITLE.BATTERY,
    noFaults: {
      count: 0,
      selected: false,
    },
    faults: {
      count: 0,
      selected: false,
    },
    alerts: {
      count: 0,
      selected: false,
    },
  },
  selectedTetherStatus: '',
  selectedLoadStatus: '',
  selectedProductTypes: [],
  selectedItemTypes: [],
  selectedConnectivityStatus: [],
  selectedFiltersCount: 0,
  selectedFilters: [],
  trailerNoFaultTotals: 0,
  trailerFaultTotals: 0,
  trailerAlertTotals: 0,
  selectedCustomer: null,
  selectedGroups: [],
  selectedVins: null,
};

const actionMappings = {
  [TOGGLE_DASHBOARD_FILTERS]: '_toggleFilters',
  [SET_DASHBOARD_FILTERS_STATUS]: '_setDashboardFiltersStatus',
  [SELECT_TETHER_STATUS]: '_selectTetherStatus',
  [SELECT_LOAD_STATUS]: '_selectLoadStatus',
  [SELECT_PRODUCT_TYPE_STATUSES]: '_selectProductTypeStatuses',
  [SELECT_ITEM_TYPE_STATUSES]: '_selectItemTypeStatuses',
  [SELECT_CONNECTIVITY_STATUS]: '_selectConnectivityStatus',
  [SELECT_ABS_NO_FAULTS_FILTER]: '_selectAbsNoFaultsFilter',
  [SELECT_ABS_FAULTS_FILTER]: '_selectAbsFaultsFilter',
  [SELECT_ABS_ALERTS_FILTER]: '_selectAbsAlertsFilter',
  [SELECT_TIS_NO_FAULTS_FILTER]: '_selectTisNoFaultsFilter',
  [SELECT_TIS_FAULTS_FILTER]: '_selectTisFaultsFilter',
  [SELECT_TIS_ALERTS_FILTER]: '_selectTisAlertsFilter',
  [SELECT_BATTERY_NO_FAULTS_FILTER]: '_selectBatteryNoFaultsFilter',
  [SELECT_BATTERY_FAULTS_FILTER]: '_selectBatteryFaultsFilter',
  [SELECT_BATTERY_ALERTS_FILTER]: '_selectBatteryAlertsFilter',
  [CLEAR_ALL_FILTERS]: '_clearAllFilters',
  [SET_FAULT_TOTALS]: '_setFaultTotals',
  [SET_SELECTED_FILTERS]: '_setSelectedFilters',
  [SET_SELECTED_CUSTOMER]: '_setSelectedCustomer',
  [SET_SELECTED_GROUPS]: '_setSelectedGroups',
  [GET_FILTER_COOKIE]: '_getFilterCookie',
};

const reducer = {
  _toggleFilters(state) {
    return {
      ...state,
      expanded: !state.expanded,
    };
  },
  _setDashboardFiltersStatus(state, { status }) {
    return {
      ...state,
      expanded: status,
    };
  },
  _selectTetherStatus(state, { selectedTetherStatus, selectedFiltersTotalCount }) {
    return {
      ...state,
      selectedTetherStatus,
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectLoadStatus(state, { selectedLoadStatus, selectedFiltersTotalCount }) {
    return {
      ...state,
      selectedLoadStatus,
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectProductTypeStatuses(state, { selectedProductTypes, selectedFiltersTotalCount }) {
    return {
      ...state,
      selectedProductTypes,
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectItemTypeStatuses(state, { itemTypes, selectedFiltersTotalCount }) {
    return {
      ...state,
      selectedItemTypes: itemTypes,
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectConnectivityStatus(state, { connectivityStatus, selectedFiltersTotalCount }) {
    return {
      ...state,
      selectedConnectivityStatus: connectivityStatus,
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectAbsNoFaultsFilter(state, { selectedFiltersTotalCount }) {
    return {
      ...state,
      absCountsFilters: {
        ...state.absCountsFilters,
        noFaults: {
          ...state.absCountsFilters.noFaults,
          selected: !state.absCountsFilters.noFaults.selected,
        },
      },
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectAbsFaultsFilter(state, { selectedFiltersTotalCount }) {
    return {
      ...state,
      absCountsFilters: {
        ...state.absCountsFilters,
        faults: {
          ...state.absCountsFilters.faults,
          selected: !state.absCountsFilters.faults.selected,
        },
      },
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectAbsAlertsFilter(state, { selectedFiltersTotalCount }) {
    return {
      ...state,
      absCountsFilters: {
        ...state.absCountsFilters,
        alerts: {
          ...state.absCountsFilters.alerts,
          selected: !state.absCountsFilters.alerts.selected,
        },
      },
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectTisNoFaultsFilter(state, { selectedFiltersTotalCount }) {
    return {
      ...state,
      tisCountsFilters: {
        ...state.tisCountsFilters,
        noFaults: {
          ...state.tisCountsFilters.noFaults,
          selected: !state.tisCountsFilters.noFaults.selected,
        },
      },
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectTisFaultsFilter(state, { selectedFiltersTotalCount }) {
    return {
      ...state,
      tisCountsFilters: {
        ...state.tisCountsFilters,
        faults: {
          ...state.tisCountsFilters.faults,
          selected: !state.tisCountsFilters.faults.selected,
        },
      },
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectTisAlertsFilter(state, { selectedFiltersTotalCount }) {
    return {
      ...state,
      tisCountsFilters: {
        ...state.tisCountsFilters,
        alerts: {
          ...state.tisCountsFilters.alerts,
          selected: !state.tisCountsFilters.alerts.selected,
        },
      },
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectBatteryNoFaultsFilter(state, { selectedFiltersTotalCount }) {
    return {
      ...state,
      batteryCountsFilters: {
        ...state.batteryCountsFilters,
        noFaults: {
          ...state.batteryCountsFilters.noFaults,
          selected: !state.batteryCountsFilters.noFaults.selected,
        },
      },
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectBatteryFaultsFilter(state, { selectedFiltersTotalCount }) {
    return {
      ...state,
      batteryCountsFilters: {
        ...state.batteryCountsFilters,
        faults: {
          ...state.batteryCountsFilters.faults,
          selected: !state.batteryCountsFilters.faults.selected,
        },
      },
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _selectBatteryAlertsFilter(state, { selectedFiltersTotalCount }) {
    return {
      ...state,
      batteryCountsFilters: {
        ...state.batteryCountsFilters,
        alerts: {
          ...state.batteryCountsFilters.alerts,
          selected: !state.batteryCountsFilters.alerts.selected,
        },
      },
      selectedFiltersCount: selectedFiltersTotalCount,
    };
  },
  _setSelectedFilters(state, { newSelectedFilters }) {
    return {
      ...state,
      selectedFilters: newSelectedFilters,
    };
  },
  _getFilterCookie(state, { filterCookie }) {
    return {
      ...state,
      absCountsFilters: {
        ...state.absCountsFilters,
        noFaults: {
          ...state.absCountsFilters.noFaults,
          selected: filterCookie.selectedFilters?.some(item => item === COUNTED_FILTERS.ABS_NO_FAULTS),
        },
        faults: {
          ...state.absCountsFilters.faults,
          selected: filterCookie.selectedFilters?.some(item => item === COUNTED_FILTERS.ABS_FAULTS),
        },
        alerts: {
          ...state.absCountsFilters.alerts,
          selected: filterCookie.selectedFilters?.some(item => item === COUNTED_FILTERS.ABS_ALERTS),
        },
      },
      tisCountsFilters: {
        ...state.tisCountsFilters,
        noFaults: {
          ...state.tisCountsFilters.noFaults,
          selected: filterCookie.selectedFilters?.some(item => item === COUNTED_FILTERS.TIS_NO_FAULTS),
        },
        faults: {
          ...state.tisCountsFilters.faults,
          selected: filterCookie.selectedFilters?.some(item => item === COUNTED_FILTERS.TIS_FAULTS),
        },
        alerts: {
          ...state.tisCountsFilters.alerts,
          selected: filterCookie.selectedFilters?.some(item => item === COUNTED_FILTERS.TIS_CAUTION_FAULTS),
        },
      },
      batteryCountsFilters: {
        ...state.batteryCountsFilters,
        noFaults: {
          ...state.batteryCountsFilters.noFaults,
          selected: filterCookie.selectedFilters?.some(item => item === COUNTED_FILTERS.BATTERY_NO_FAULTS),
        },
        faults: {
          ...state.batteryCountsFilters.faults,
          selected: filterCookie.selectedFilters?.some(item => item === COUNTED_FILTERS.BATTERY_FAULTS),
        },
        alerts: {
          ...state.batteryCountsFilters.alerts,
          selected: filterCookie.selectedFilters?.some(item => item === COUNTED_FILTERS.BATTERY_CAUTION_FAULTS),
        },
      },
      selectedFilters: filterCookie?.selectedFilters ?? initialState?.selectedFilters,
      selectedTetherStatus: filterCookie?.selectedTetherStatus ?? initialState?.selectedTetherStatus,
      selectedLoadStatus: filterCookie?.selectedLoadStatus ?? initialState?.selectedLoadStatus,
      selectedProductTypes: filterCookie?.selectedProductTypes ?? initialState?.selectedProductTypes,
      selectedItemTypes: filterCookie?.selectedItemTypes ?? initialState?.selectedItemTypes,
      selectedConnectivityStatus: filterCookie?.selectedConnectivityStatus ?? initialState?.selectedConnectivityStatus,
      selectedFiltersCount: filterCookie?.selectedFilters?.length ?? initialState?.selectedFiltersCount,
      selectedCustomer: filterCookie?.selectedCustomer ?? initialState?.selectedCustomer,
      selectedGroups: filterCookie?.selectedGroups ?? initialState?.selectedGroups,
      selectedVins: filterCookie?.selectedVins ?? initialState?.selectedVins,
    };
  },
  _setSelectedCustomer(state, { selectedCustomer }) {
    return {
      ...state,
      selectedCustomer,
    };
  },
  _setSelectedGroups(state, { selectedGroups, selectedVins }) {
    return {
      ...state,
      selectedGroups,
      selectedVins,
    };
  },
  _clearAllFilters(state) {
    return {
      ...state,
      absCountsFilters: {
        ...state.absCountsFilters,
        noFaults: {
          ...state.absCountsFilters.noFaults,
          selected: false,
        },
        faults: {
          ...state.absCountsFilters.faults,
          selected: false,
        },
        alerts: {
          ...state.absCountsFilters.alerts,
          selected: false,
        },
      },
      tisCountsFilters: {
        ...state.tisCountsFilters,
        noFaults: {
          ...state.tisCountsFilters.noFaults,
          selected: false,
        },
        faults: {
          ...state.tisCountsFilters.faults,
          selected: false,
        },
        alerts: {
          ...state.tisCountsFilters.alerts,
          selected: false,
        },
      },
      batteryCountsFilters: {
        ...state.batteryCountsFilters,
        noFaults: {
          ...state.batteryCountsFilters.noFaults,
          selected: false,
        },
        faults: {
          ...state.batteryCountsFilters.faults,
          selected: false,
        },
        alerts: {
          ...state.batteryCountsFilters.alerts,
          selected: false,
        },
      },
      selectedTetherStatus: '',
      selectedLoadStatus: '',
      selectedProductTypes: [],
      selectedItemTypes: [],
      selectedConnectivityStatus: [],
      selectedFiltersCount: 0,
      selectedFilters: [],
    };
  },
  _setFaultTotals(state, { faultTotals }) {
    return {
      ...state,
      absCountsFilters: {
        ...state.absCountsFilters,
        noFaults: {
          ...state.absCountsFilters.noFaults,
          count: faultTotals.absNoFaultsCount,
        },
        faults: {
          ...state.absCountsFilters.faults,
          count: faultTotals.absFaultsCount,
        },
        alerts: {
          ...state.absCountsFilters.alerts,
          count: faultTotals.absAlertsCount,
        },
      },
      tisCountsFilters: {
        ...state.tisCountsFilters,
        noFaults: {
          ...state.tisCountsFilters.noFaults,
          count: faultTotals.tisNoFaultsCount,
        },
        faults: {
          ...state.tisCountsFilters.faults,
          count: faultTotals.tisFaultsCount,
        },
        alerts: {
          ...state.tisCountsFilters.alerts,
          count: faultTotals.tisAlertsCount,
        },
      },
      batteryCountsFilters: {
        ...state.batteryCountsFilters,
        noFaults: {
          ...state.batteryCountsFilters.noFaults,
          count: faultTotals.batteryNoFaultsCount,
        },
        faults: {
          ...state.batteryCountsFilters.faults,
          count: faultTotals.batteryFaultsCount,
        },
        alerts: {
          ...state.batteryCountsFilters.alerts,
          count: faultTotals.batteryAlertsCount,
        },
      },
      trailerNoFaultTotals: faultTotals.trailerNoFaultTotals,
      trailerFaultTotals: faultTotals.trailerFaultTotals,
      trailerAlertTotals: faultTotals.trailerAlertTotals,
    };
  },
};

const dashboardFiltersReducer = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default dashboardFiltersReducer;
