import { handleErrors } from '../utils/errorUtils';
import { fleetpulseApiFetch as fetch } from '../utils/fetch';

export const fetchVehicleGroups = async customerId => {
  return fetch(`/v1/api/vehicle-groups/hierarchy?customerId=${customerId}`, { method: 'GET' });
};

const doFetch = (query, variables, { displayWarningCallback = () => {}, displaySuccessModal = () => {}, setUpdateFieldLoading = () => {} } = {}) => {
  try {
    return fetch('/graphql', {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables,
      }),
    })
      .then(response => {
        if (!response.ok) {
          displayWarningCallback('Unknown error occured!');
          handleErrors(response);
          throw Error('Unknown error occured!');
        }
        return response.json();
      })
      .then(json => {
        const { errors, data } = json;
        if (errors && errors?.length > 0) {
          let errorMessage = errors[0]?.message ?? 'Unknown error occured!';
          displayWarningCallback(errorMessage);
        } else {
          displaySuccessModal('Request completed successfully!');
        }
        return data;
      })
      .then(({ success }) => {
        setUpdateFieldLoading(false);
        return success;
      });
  } catch (error) {
    console.log('error', error);
    displayWarningCallback(error);
    setUpdateFieldLoading(false);
  }
};

export const updateVehicleGroup = async ({ customerId, parentIds, name }) => {
  const query = `
    mutation updateVehicleGroup($customerId: Int, $parentIds: [UUID], $name: String!) {
      success: updateVehicleGroup(input: {customerId: $customerId, parentIds: $parentIds, name: $name})
    }
  `;

  const updateVehicleGroupParams = { customerId, parentIds, name };

  return doFetch(query, updateVehicleGroupParams);
};

export const deleteVehicleGroup = async ({ customerId, name }) => {
  const query = `
    mutation deleteVehicleGroup($customerId: Int, $name: String) {
      success: deleteVehicleGroup(input: {customerId: $customerId, name: $name})
    }
  `;

  const deleteVehicleGroupParams = { customerId, name };

  return doFetch(query, deleteVehicleGroupParams, {});
};

export const updateCustomerVehicle = async ({ vin, unitId }, updateVehicleCtx) => {
  const query = `
    mutation UpdateCustomerVehicle($vin: String!, $unitId: String!) {
      success: updateCustomerVehicle(vin: $vin, unitId: $unitId) {
        id
        extCustomerVehicleId
      }
    }
  `;

  const updateCustomerVehicleParams = { vin, unitId };

  return doFetch(query, updateCustomerVehicleParams, updateVehicleCtx);
};

export const updateVehicle = async (
  { vin, restingBogieWeightLbs, hasUltrasonicCargoSensor, cargoCameraSerialNumber, hasCargoCameraSensor, thermoKingExternalId, hasThermoKingSensor },
  updateVehicleCtx,
) => {
  const query = `
    mutation updateVehicle($vin: String, $restingBogieWeightLbs: Int, $hasUltrasonicCargoSensor: Boolean, $cargoCameraSerialNumber: String, $hasCargoCameraSensor: Boolean, $thermoKingExternalId: String, $hasThermoKingSensor: Boolean) {
      success: updateVehicle(input: {vin: $vin, restingBogieWeightLbs: $restingBogieWeightLbs, hasUltrasonicCargoSensor: $hasUltrasonicCargoSensor, cargoCameraSerialNumber: $cargoCameraSerialNumber, hasCargoCameraSensor: $hasCargoCameraSensor, thermoKingExternalId: $thermoKingExternalId, hasThermoKingSensor: $hasThermoKingSensor})
    }
  `;

  const updateVehicleParams = {
    vin,
    restingBogieWeightLbs,
    hasUltrasonicCargoSensor,
    cargoCameraSerialNumber,
    hasCargoCameraSensor,
    thermoKingExternalId,
    hasThermoKingSensor,
  };

  return doFetch(query, updateVehicleParams, updateVehicleCtx);
};

export const renameVehicleGroup = async ({ customerId, vehicleGroupId, name }) => {
  const query = `
    mutation renameVehicleGroup($customerId: Int, $vehicleGroupId: UUID, $name: String!) {
      success: renameVehicleGroup(input: {customerId: $customerId, vehicleGroupId: $vehicleGroupId, name: $name}) {
        id,
        name
      }
    }
  `;

  const renameVehicleGroupParams = { customerId, vehicleGroupId, name };

  return doFetch(query, renameVehicleGroupParams);
};

export const addVehiclesToGroups = async ({ vehicleIds, customerId, vehicleGroupIds }, updateVehicleCtx) => {
  const query = `
    mutation addVehiclesToGroups($vehicleIds: [UUID]!, $customerId: Int, $vehicleGroupIds: [UUID]) {
      success: addVehiclesToGroups(input: {vehicleIds: $vehicleIds, customerId: $customerId, vehicleGroupIds: $vehicleGroupIds})
    }
  `;

  const addVehiclesToGroupsParams = { vehicleIds, customerId, vehicleGroupIds };

  return doFetch(query, addVehiclesToGroupsParams, updateVehicleCtx);
};

export const removeVehiclesFromGroups = async ({ vehicleIds, customerId, vehicleGroupIds }, updateVehicleCtx) => {
  const query = `
    mutation removeVehiclesFromGroups($vehicleIds: [UUID]!, $customerId: Int, $vehicleGroupIds: [UUID]) {
      success: removeVehiclesFromGroups(input: {vehicleIds: $vehicleIds, customerId: $customerId, vehicleGroupIds: $vehicleGroupIds})
    }
  `;

  const removeVehiclesFromGroupsParams = { vehicleIds, customerId, vehicleGroupIds };

  return doFetch(query, removeVehiclesFromGroupsParams, updateVehicleCtx);
};

export const addUsersToVehicleGroupsByIdApi = async ({ userIds, customerId, vehicleGroupIds }) => {
  const query = `
    mutation addUsersToVehicleGroups($userIds: [UUID]!, $customerId: Int!, $vehicleGroupIds: [UUID]) {
      success: addUsersToVehicleGroups(input: {userIds: $userIds, customerId: $customerId, vehicleGroupIds: $vehicleGroupIds})
    }
  `;

  const addUsersToVehicleGroupsParams = { userIds, customerId, vehicleGroupIds };

  return doFetch(query, addUsersToVehicleGroupsParams);
};

export const deleteUsersFromVehicleGroupsByIdApi = async ({ userIds, customerId, vehicleGroupIds }) => {
  const query = `
    mutation removeUsersFromVehicleGroups($userIds: [UUID]!, $customerId: Int!, $vehicleGroupIds: [UUID]) {
      success: removeUsersFromVehicleGroups(input: {userIds: $userIds, customerId: $customerId, vehicleGroupIds: $vehicleGroupIds})
    }
  `;

  const removeUsersFromVehicleGroupsParams = { userIds, customerId, vehicleGroupIds };

  return doFetch(query, removeUsersFromVehicleGroupsParams);
};
