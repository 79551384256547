import _uniqBy from 'lodash/uniqBy';
import sensorStatusTypes from '../constants/enums/sensorStatusTypes';
import { getCargoWeightOnBogieTextAgGrid, roundMileageAgGrid } from './agGridUtils';
import { REEFER_UNIT_STATUS } from '../constants/enums/thermoKing';
import fleetStatusFaultFilters from '../constants/enums/fleetStatusFaultFilters';
import fleetStatusAttributes from '../constants/enums/fleetStatusAttributes';
import { arrayToStringFormating } from './customStringUtils';
import { titleCase } from './textUtils';
import { PRODUCT_TYPE } from '../constants/enums/filters';

const findVehicleGroups = (vehicleGroups, groupName) => {
  return vehicleGroups.flatMap(it => {
    if (it.name === groupName) return it;
    else if (it.vehicleGroups) return findVehicleGroups(it.vehicleGroups, groupName);
  });
};

const getSelectedGroupsWithChildren = (selectedGroups, vehicleGroups) => {
  return selectedGroups.map(group => findVehicleGroups(vehicleGroups, group.name));
};

const getGroupChildren = groups => {
  const groupsWithChildren = [];
  (groups || []).forEach(({ id, name, vehicleGroups }) => {
    groupsWithChildren.push({ id, name });
    const [children] = getGroupChildren(vehicleGroups);
    groupsWithChildren.push(...children);
  });

  return [groupsWithChildren];
};

export const groupsFilter = (selectedGroups, formattedFleetStatusData, vehicleGroups) => {
  const selectedParent = getSelectedGroupsWithChildren(selectedGroups, vehicleGroups).flat();
  const selectedGroupsWithChildren = _uniqBy(getGroupChildren(selectedParent).flat(), it => it.id);
  const selectedGroupNames = selectedGroupsWithChildren.map(it => it.name);
  const filteredGroups = formattedFleetStatusData.filter(item => item.group?.some(group => selectedGroupNames?.includes(group)));
  return { filteredGroups, selectedGroupsWithChildren };
};

export const formatWeightStatus = ({ data, value }) => {
  const { isLoaded = null, isGoTrailer = null, netWeightLbs = null } = data?.netWeightLbsData ?? {};

  if (value) {
    return getCargoWeightOnBogieTextAgGrid(value);
  }
  if (isLoaded && isGoTrailer) {
    return `${getCargoWeightOnBogieTextAgGrid(netWeightLbs)} - Ultrasonic Sensor`;
  }

  return getCargoWeightOnBogieTextAgGrid(netWeightLbs);
};

const getPowerOnStatus = powerOnValue => {
  if (powerOnValue === null || powerOnValue === undefined) {
    return null;
  }
  return powerOnValue ? 'On' : 'Off';
};

export const formatFleetStatusData = trailer => {
  const thermoKing = trailer?.isThermoking ? PRODUCT_TYPE.TRU.name : null;
  const productType = [trailer?.baseProductType, trailer?.goProductType, thermoKing].filter(item => item);

  return {
    vin: trailer?.vin ?? null,
    unitId: trailer?.unitId ?? null,
    serialNumber: trailer?.serialNumber ?? null,
    productType: titleCase(arrayToStringFormating(productType)),
    mainAppVersion: trailer?.mainAppVersion ?? null,
    lastReport: trailer?.lastReport ?? null,
    installationDate: trailer?.installationDate,
    group: trailer?.groups ?? null,
    odometer: roundMileageAgGrid(trailer?.absOdometer ?? null),
    gpsOdometer: roundMileageAgGrid(trailer?.gpsOdometer ?? null),
    tether: trailer?.tetheredStatus ?? null,
    liveUntetheredDurationMs: trailer?.timeUntetheredDays ?? null,
    occupiedCustomerSpecificGeofences: trailer?.geofence ?? null,
    enhancedWeight: trailer?.loadStatus,
    netWeightLbs: trailer?.cargoWeightOnBogieLbs ?? null,
    reverseGeo: trailer?.address ?? null,
    door: trailer?.doorStatus ?? null,
    absFault: trailer?.absFault ?? null,
    tis: trailer?.tireInflationStatus ?? null,
    lightFault: trailer?.lightFault ?? null,
    sensorBoxBattery: trailer?.trailerBatteryVoltage ?? null,
    sensorBoxBatteryHealthStatus: trailer?.trailerBatteryStatus ?? 'N/A',
    gatewayBatteryHealthStatus: trailer?.gatewayBatteryStatus ?? 'N/A',
    trailerHealthStatus: trailer?.trailerHealthStatus ?? 'N/A',
    connectivityStatus: trailer?.connectivityStatus ?? 'N/A',
    lat: trailer?.lat?.toFixed(4) ?? null,
    lng: trailer?.lng?.toFixed(4) ?? null,
    speed: trailer?.speed ?? null,
    fullness: trailer?.cargoCameraFullness ?? null,
    powerOn: getPowerOnStatus(trailer?.tkUnitOnOrOff ?? null),
    unitModeDetail: REEFER_UNIT_STATUS[trailer?.tkOperatingMode] ?? null,
    fuelLevel: trailer?.tkFuelLevel ? (trailer?.tkFuelLevel * 100)?.toFixed(0) ?? null : null,
    ambientTemperature: trailer?.tkAmbientF ?? null,
    humidity: trailer?.tkHumidity ?? null,
    indSensor1: trailer?.interiorTemp1F ?? null,
    indSensor2: trailer?.interiorTemp2F ?? null,
    indSensor3: trailer?.interiorTemp3F ?? null,
    indSensor4: trailer?.interiorTemp4F ?? null,
    indSensor5: trailer?.interiorTemp5F ?? null,
    indSensor6: trailer?.interiorTemp6F ?? null,
    setPoint1: trailer?.setPoint1F ?? null,
    setPoint2: trailer?.setPoint2F ?? null,
    setPoint3: trailer?.setPoint3F ?? null,
    dischargeAir1: trailer?.dischargeAir1F ?? null,
    dischargeAir2: trailer?.dischargeAir2F ?? null,
    dischargeAir3: trailer?.dischargeAir3F ?? null,
    returnAir1: trailer?.returnAir1F ?? null,
    returnAir2: trailer?.returnAir2F ?? null,
    returnAir3: trailer?.returnAir3F ?? null,
    engineHours: trailer?.tkEngineHours ?? null,
    electricalHours: trailer?.tkElectricalHours ?? null,
    totalHours: trailer?.tkTotalHours ?? null,
    voltage: trailer?.tkUnitBatteryVoltage ?? null,
  };
};

export const filteredDataByFault = (filteredFleetStatusData, sensorType, TIS) =>
  filteredFleetStatusData.filter(item =>
    sensorType === TIS ? item.tis?.toLowerCase() === sensorStatusTypes.UNSTABLE.display.toLowerCase() : item[sensorType],
  );

export const getColumnFieldName = colId => {
  return Object.values(fleetStatusAttributes).find(column => column.apiResponseParsePath === colId).fieldName;
};

const isOneOfAttributes = (attributeName, attributeList) => attributeList.map(attrFromList => attrFromList.name).includes(attributeName);

const PRODUCT_TYPE_FILTER = {
  'Pro': `{ fieldName: "base_product_type", isExactly: "pro"}`,
  'Base': `{ fieldName: "base_product_type", isExactly: "base"}`,
  'GO: Solar': `{ fieldName: "go_product_type", isExactly: "solar"}`,
  'GO: Track': `{ fieldName: "go_product_type", isExactly: "track"}`,
  'GO: Cargo View': `{ fieldName: "go_product_type", isExactly: "cargo view"}`,
  'GO: Cargo Monitor': `{ fieldName: "go_product_type", isExactly: "cargo monitor"}`,
  'TRU': `{ fieldName: "is_thermoking", isExactly: "true"}`,
};

export const formatFilters = filters => {
  if (!filters) return;

  const formattedFilter = filters?.map(item => {
    const isABS= item[0] === fleetStatusFaultFilters.ABS_FAULTS_FILTER.type.name;
    const isTIS = item[0] === fleetStatusFaultFilters.TIS_FAULTS_FILTER.type.name;
    const dropdownFields = [
      fleetStatusAttributes.TETHERED_STATUS,
      fleetStatusAttributes.LOAD_STATUS,
      fleetStatusAttributes.DOOR_STATUS,
      fleetStatusAttributes.PRODUCT_TYPE,
    ];
    if (isOneOfAttributes(item[0], dropdownFields)) {
      if (item[0] === fleetStatusAttributes.PRODUCT_TYPE.name) {
        return PRODUCT_TYPE_FILTER[item[1].filter];
      }
      return `{ fieldName: "${getColumnFieldName(item[0])}", isExactly: "${item[1]?.filter}"}`;
    }

    if (isABS && !item[1].type) {
      return `{ fieldName: "${getColumnFieldName(item[0])}", isNull: ${Boolean(item[1]?.filter !== 'true')} }`;
    }

    if(isTIS && !item[1].type) {
      return `{ fieldName: "${getColumnFieldName(item[0])}", likeRegex: "unstable|not available" }`;
    }

    return `{ fieldName: "${getColumnFieldName(item[0])}", like: "${item[1]?.filter}" }`;
  });

  return formattedFilter ?? '';
};
