import { hasCautionAbsSeverityLevel, hasCriticalAbsSeverityLevel } from '../constants/enums/absFaults';
import { COUNTED_FILTERS, PRODUCT_TYPE, ITEM_TYPE, LOAD_STATUS, SENSOR_CONNECTIVITY, TETHER_STATUS } from '../constants/enums/filters';
import vehicleTypes from '../constants/enums/vehicleTypes';
import sensorTypeIds from '../constants/enums/sensorTypeIds';
import { TRAILER_BATTERY_STATUS } from "../constants/enums/trailerBattery"

export const handleMapFiltering = ({ selectedFilters, allTrucks, selectedCustomer, selectedVins }) => {
  let filteredTrailers = allTrucks;

  filteredTrailers = getAbsNoFaultTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getAbsCriticalStatusTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getAbsCautionStatusTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getTisNoFaultsTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getTisFaultsTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getTisCautionFaultsTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getBatteryNoFaultsTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getBatteryFaultsTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getBatteryCautionFaultsTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getLoadedTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getUnloadedTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getTetheredTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getUntetheredTrucks({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getTrailersBasedOnProductType({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getTrailersBasedOnType({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getTrailersBasedOnConnectivityStatus({ selectedFilters, allTrucks: filteredTrailers });
  filteredTrailers = getTrailersBasedOnCustomerAndGroups({ filteredTrailers, selectedCustomer, selectedVins });
  return filteredTrailers;
};

const getAbsNoFaultTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(COUNTED_FILTERS.ABS_NO_FAULTS)) {
    return allTrucks?.filter(trailerHasAbsInstalled)?.filter(truck => !truck?.absSeverityLevelId) ?? [];
  } else return allTrucks;
};

const getAbsCriticalStatusTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(COUNTED_FILTERS.ABS_FAULTS)) {
    return (
      allTrucks?.filter(trailerHasAbsInstalled)?.filter(truck => hasCriticalAbsSeverityLevel(truck?.absSeverityLevelId)) ??
      []
    );
  } else return allTrucks;
};

const getAbsCautionStatusTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(COUNTED_FILTERS.ABS_ALERTS)) {
    return (
      allTrucks?.filter(trailerHasAbsInstalled)?.filter(truck => hasCautionAbsSeverityLevel(truck?.absSeverityLevelId)) ??
      []
    );
  } else return allTrucks;
};

const getTisNoFaultsTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(COUNTED_FILTERS.TIS_NO_FAULTS)) {
    return allTrucks?.filter(trailerHasTisInstalled)?.filter(truck => truck?.tireInflationStatus?.toLowerCase() !== 'unstable' || !truck.tireInflationStatus) ?? [];
  } else return allTrucks;
};

const getTisFaultsTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(COUNTED_FILTERS.TIS_FAULTS)) {
    return allTrucks?.filter(trailerHasTisInstalled)?.filter(truck => truck?.tireInflationStatus?.toLowerCase() === 'unstable') ?? [];
  } else return allTrucks;
};

const getTisCautionFaultsTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(COUNTED_FILTERS.TIS_CAUTION_FAULTS)) {
    return allTrucks?.filter(trailerHasTisInstalled)?.filter(truck => truck?.tireInflationStatus?.toLowerCase() === 'caution') ?? [];
  } else return allTrucks;
};

const getBatteryNoFaultsTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(COUNTED_FILTERS.BATTERY_NO_FAULTS)) {
    return allTrucks?.filter(truck => truck?.trailerBatteryStatus === TRAILER_BATTERY_STATUS.GOOD || (!truck?.trailerBatteryStatus && truck?.gatewayBatteryStatus === TRAILER_BATTERY_STATUS.GOOD)) ?? [];
  } else return allTrucks;
};

const getBatteryFaultsTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(COUNTED_FILTERS.BATTERY_FAULTS)) {
    return allTrucks?.filter(truck => truck?.trailerBatteryStatus === TRAILER_BATTERY_STATUS.CRITICAL || (!truck?.trailerBatteryStatus && truck?.gatewayBatteryStatus === TRAILER_BATTERY_STATUS.CRITICAL)) ?? [];
  } else return allTrucks;
};

const getBatteryCautionFaultsTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(COUNTED_FILTERS.BATTERY_CAUTION_FAULTS)) {
    return allTrucks?.filter(truck => truck?.trailerBatteryStatus === TRAILER_BATTERY_STATUS.CAUTION || (!truck?.trailerBatteryStatus && truck?.gatewayBatteryStatus === TRAILER_BATTERY_STATUS.CAUTION)) ?? [];
  } else return allTrucks;
};

export const getTrailersBasedOnCustomerAndGroups = ({ filteredTrailers, selectedCustomer, selectedVins }) => {
  // Filter by customer and VINs
  if (selectedCustomer) {
    filteredTrailers = filteredTrailers?.filter(truck => truck.customerId.toString() === selectedCustomer.id) ?? [];
    if (selectedVins?.length > 0) {
      filteredTrailers = filteredTrailers?.filter(truck => selectedVins?.includes(truck.vinNumber)) ?? [];
    }
  }
  return filteredTrailers;
};

const getLoadedTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(LOAD_STATUS.LOADED)) {
    return allTrucks?.filter(tailerHasLoadStatus)?.filter(truck => truck?.isLoaded);
  } else return allTrucks;
};

const getUnloadedTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(LOAD_STATUS.UNLOADED)) {
    return allTrucks?.filter(tailerHasLoadStatus)?.filter(truck => !truck?.isLoaded);
  } else return allTrucks;
};

const getTetheredTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(TETHER_STATUS.TETHERED)) {
    return allTrucks?.filter(trailerHasTetherStatusInstalled)?.filter(truck => truck?.isTethered);
  } else return allTrucks;
};

const getUntetheredTrucks = ({ selectedFilters, allTrucks }) => {
  if (selectedFilters?.includes(TETHER_STATUS.UNTETHERED)) {
    return allTrucks?.filter(trailerHasTetherStatusInstalled)?.filter(truck => !truck?.isTethered);
  } else return allTrucks;
};

const getTrailersBasedOnType = ({ selectedFilters, allTrucks }) => {
  const dryItemSelected = selectedFilters?.some(filter => filter === ITEM_TYPE.DRY);
  const refeerItemSelected = selectedFilters?.some(filter => filter === ITEM_TYPE.REEFER);
  const flatItemSelected = selectedFilters?.some(filter => filter === ITEM_TYPE.FLAT);

  if (dryItemSelected || refeerItemSelected || flatItemSelected) {
    return allTrucks
      ?.filter(trailer => trailer?.vehicleTypeId)
      ?.filter(
        truck =>
          (dryItemSelected && truck.vehicleTypeId === vehicleTypes.dryTrailer.id) ||
          (refeerItemSelected && truck.vehicleTypeId === vehicleTypes.reeferTrailer.id) ||
          (flatItemSelected && truck.vehicleTypeId === vehicleTypes.flat.id),
      );
  } else return allTrucks;
};

const getTrailersBasedOnConnectivityStatus = ({ selectedFilters, allTrucks }) => {
  const goodStatusSelected = selectedFilters?.some(filter => filter === SENSOR_CONNECTIVITY.GOOD);
  const cautionStatusSelected = selectedFilters?.some(filter => filter === SENSOR_CONNECTIVITY.CAUTION);
  const criticalStatusSelected = selectedFilters?.some(filter => filter === SENSOR_CONNECTIVITY.CRITICAL);

  if (goodStatusSelected || cautionStatusSelected || criticalStatusSelected) {
    return allTrucks?.filter(
      truck =>
        (goodStatusSelected && truck.connectivityStatus === SENSOR_CONNECTIVITY.GOOD) ||
        (cautionStatusSelected && truck.connectivityStatus === SENSOR_CONNECTIVITY.CAUTION) ||
        (criticalStatusSelected && truck.connectivityStatus === SENSOR_CONNECTIVITY.CRITICAL),
    );
  } else return allTrucks;
};

const getTrailersBasedOnProductType = ({ selectedFilters, allTrucks }) => {
  const proSelected = selectedFilters?.some(filter => filter === PRODUCT_TYPE.PRO.value);
  const baseSelected = selectedFilters?.some(filter => filter === PRODUCT_TYPE.BASE.value);
  const goSolarSelected = selectedFilters?.some(filter => filter === PRODUCT_TYPE.GO_SOLAR.value);
  const goTrackSelected = selectedFilters?.some(filter => filter === PRODUCT_TYPE.GO_TRACK.value);
  const goCargoViewSelected = selectedFilters?.some(filter => filter === PRODUCT_TYPE.GO_CARGO_VIEW.value);
  const goCargoMonitorSelected = selectedFilters?.some(filter => filter === PRODUCT_TYPE.GO_CARGO_MONITOR.value);
  const thermoKingSelected = selectedFilters?.some(filter => filter === PRODUCT_TYPE.TRU.value);

  if (proSelected || baseSelected || goSolarSelected || goTrackSelected || goCargoViewSelected || goCargoMonitorSelected || thermoKingSelected) {
    return allTrucks?.filter(
      truck =>
        (proSelected && truck?.baseProductType === PRODUCT_TYPE.PRO.value) ||
        (baseSelected && truck?.baseProductType === PRODUCT_TYPE.BASE.value) ||
        (goSolarSelected && truck?.goProductType === PRODUCT_TYPE.GO_SOLAR.value) ||
        (goTrackSelected && truck?.goProductType === PRODUCT_TYPE.GO_TRACK.value) ||
        (goCargoViewSelected && truck?.goProductType === PRODUCT_TYPE.GO_CARGO_VIEW.value) ||
        (goCargoMonitorSelected && truck?.goProductType === PRODUCT_TYPE.GO_CARGO_MONITOR.value) ||
        (thermoKingSelected && truck?.isThermoking === true),
    );
  }
  return allTrucks;
};

export const trailerHasTisInstalled = trailer => trailer?.installedSensorTypeIdsList?.some(id => sensorTypeIds.tireInflationStatus.id === id);

export const trailerHasAbsInstalled = trailer => trailer?.installedSensorTypeIdsList?.some(id => sensorTypeIds.absSystemFault.id === id);

export const tailerHasLoadStatus = trailer => trailer?.installedSensorTypeIdsList?.some(id => sensorTypeIds.enhancedWeight.id === id || sensorTypeIds.ultrasonicCargo.id === id || sensorTypeIds.cargoCamera.id === id);

export const trailerHasTetherStatusInstalled = trailer => trailer?.installedSensorTypeIdsList?.some(id => sensorTypeIds.tetherChange.id === id);
