import React from 'react';
import { useHistory } from 'react-router-dom';
import './SettingsNavigationTabs.css';
import pageNames from '../../constants/enums/pageNames';
import { isTabActive } from '../../utils/navigationUtils';
import { isUserAdmin, isUserDealer, isUserSupport } from '../../../src/security/authorization';
import { useSelector } from 'react-redux';
import Tab from '../Tab/Tab';

const SettingsNavigationTabs = () => {
  let history = useHistory();
  const { OtaManagement, VinTransferPortal } = useSelector(state => state.featureFlag?.features);
  const { userManagement, trailerManagement, groupManagement, customerManagement, otaManagement, vinTransfer } = pageNames;
  const isUserTabActive = isTabActive(userManagement);
  const isTrailerTabActive = isTabActive(trailerManagement);
  const isGroupTabActive = isTabActive(groupManagement);
  const isCustomerTabActive = isTabActive(customerManagement);
  const isVinTransferActive = isTabActive(vinTransfer);
  const isOtaTabActive = isTabActive(otaManagement);
  const vinTransferAccess = isUserAdmin() || isUserSupport() || isUserDealer();

  return (
    <div className="userManagement-navigation">
      <Tab tabLabel="User Management" onClick={() => history.push('/user-management')} isActive={isUserTabActive} />
      <Tab tabLabel="Trailer Management" onClick={() => history.push('/trailer-management')} isActive={isTrailerTabActive} />
      <Tab tabLabel="Group Management" onClick={() => history.push('/group-management')} isActive={isGroupTabActive} />
      {isUserAdmin() && (
        <>
          <Tab tabLabel="Customer Management" onClick={() => history.push('/customer-management')} isActive={isCustomerTabActive} />
          {OtaManagement && <Tab tabLabel="OTA Management" onClick={() => history.push('/ota-management')} isActive={isOtaTabActive} />}
        </>
      )}
      {vinTransferAccess && VinTransferPortal && <Tab tabLabel="VIN Transfer" onClick={() => history.push('/vin-transfer')} isActive={isVinTransferActive} />}
    </div>
  );
};

export default SettingsNavigationTabs;
