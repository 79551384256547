import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import CrossHairs from 'mdi-react/TargetIcon';
import FleetStatusList from 'mdi-react/FormatListBulletedIcon';
import WrenchIcon from 'mdi-react/WrenchIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import AlertBell from 'mdi-react/BellIcon';
import QuestionCircle from 'mdi-react/HelpCircleOutlineIcon';
import HeaderEmpty from '../../HeaderEmpty/HeaderEmpty';
import { ReactComponent as MainLogo } from '../../../assets/public/FleetPulse_Logo.svg';
import { ReactComponent as MainLogoWithOutText } from '../../../assets/public/fleetPulseMark.svg';
import CustomHeaderButton from './CustomHeaderButton';
import UserPanelButton from './UserPanelButton';
import tenantFeatures from '../../../features';
import { fetchVehicleGroupsData } from '../../../actions/vehicleGroups';
import { checkIsUserAuthenticated } from '../../../actions/login';
import { isUserCustomer, roleBasedAuthorization } from '../../../security/authorization';
import { NO_VINS_GROUP } from '../../../constants';
import { shouldHideFAQ } from '../../../utils/faqUtils';
import headerNavigationNames from '../../../constants/enums/headerNavigationNames';
import pageNames, { PUBLIC_ROUTES } from '../../../constants/enums/pageNames';
import { fleetpulseApiFetch } from '../../../utils/fetch';
import '../styles/Header.css';
import { fetchCustomersData } from '../../../actions/customers';
import { fetchFeatureFlags } from '../../../actions/featureToggle';
import { ReactComponent as MaintenanceIcon } from '../../../assets/public/maintenance.svg';

function Header() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const features = useSelector(state => state.featureFlag.features);
  const { allTrucks } = useSelector(state => state.tracking);
  const { isAuthenticated, userEmail } = useSelector(state => state.login);
  const vehicleGroups = useSelector(state => state.vehicleGroups);
  const [activeScreen, setActiveScreen] = useState(headerNavigationNames.TRACKING.pathName);
  const filterNoVinsGroup = vehicleGroups?.vehicleGroupsData?.vehicleGroups.filter(group => group.name !== NO_VINS_GROUP);
  const noVINsAssigned = _isEmpty(filterNoVinsGroup);
  const publicRoutes = Object.values(PUBLIC_ROUTES).map(route => route.route);
  const isRoutePublic = publicRoutes.includes(activeScreen);
  const initialVinNumber = allTrucks[0]?.vinNumber ?? '';

  useEffect(() => {
    if (userEmail) {
      dispatch(fetchCustomersData());
    }
  }, [dispatch, userEmail]);

  useEffect(() => {
    dispatch(checkIsUserAuthenticated());
    if (isUserCustomer()) {
      const customerId = window.localStorage.getItem('customerId');
      dispatch(fetchVehicleGroupsData(customerId, fleetpulseApiFetch));
    }
  }, [dispatch]);

  useEffect(() => {
    const customerId = window.localStorage.getItem('customerId');

    if (customerId) {
      dispatch(fetchFeatureFlags(customerId));
    }
  }, [dispatch]);

  useEffect(() => {
    setActiveScreen(pathname.replace('/', ''));
  }, [pathname]);

  if (activeScreen === pageNames.freight || activeScreen === pageNames.freightLink) {
    return null;
  }

  if (!isAuthenticated || isRoutePublic) {
    return <HeaderEmpty />;
  }

  return (
    <header className="header-app-header">
      <div className="header-app-logo">
        <MainLogo className="header-app-logo-img" />
        <MainLogoWithOutText className="header-app-logo-img-small" />
      </div>
      <div className="header-route-links">
        <CustomHeaderButton activeScreen={activeScreen} {...headerNavigationNames.TRACKING} icon={<CrossHairs size={27} />} />
        {roleBasedAuthorization(headerNavigationNames.FLEET.name) && (
          <CustomHeaderButton activeScreen={activeScreen} {...headerNavigationNames.FLEET} icon={<FleetStatusList size={27} />} />
        )}
        {roleBasedAuthorization(headerNavigationNames.ALERTS.name) && (
          <CustomHeaderButton activeScreen={activeScreen} {...headerNavigationNames.ALERTS} icon={<AlertBell size={27} />} />
        )}
        {features.MaintenanceDashboard && <CustomHeaderButton activeScreen={activeScreen} {...headerNavigationNames.MAINTENANCE} icon={<MaintenanceIcon />} />}
        {tenantFeatures.PARTS_HEADER_OPTION && roleBasedAuthorization(headerNavigationNames.PARTS.name) && (
          <CustomHeaderButton
            activeScreen={activeScreen}
            {...headerNavigationNames.PARTS}
            url={`${headerNavigationNames.PARTS.url}/${initialVinNumber}`}
            icon={<WrenchIcon size={27} />}
          />
        )}
        {shouldHideFAQ(noVINsAssigned) && (
          <CustomHeaderButton activeScreen={activeScreen} {...headerNavigationNames.HELP} icon={<QuestionCircle size={27} />} />
        )}
        {features.FreightIframe && (
          <CustomHeaderButton activeScreen={activeScreen} {...headerNavigationNames.FREIGHT} icon={<FontAwesomeIcon icon={faTruckLoading} />} target="_blank" />
        )}
      </div>
      <UserPanelButton />
    </header>
  );
}

export default Header;
