import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Button.css';
import LoadingCircle from '../LoadingCircle/LoadingCircle';

function Button({ buttonText, onClick, size, color, disabled, leftIcon, rightIcon, type, style, outlineButton, isLoading, ghostButton, linkButton }) {
  const getColor = color => {
    switch (color) {
      case 'primary':
      case 'blue':
        return 'blue';
      case 'secondary':
        return 'secondary';
      case 'danger':
      case 'red':
        return 'red';
      case 'white':
        return 'white';
      default:
        return 'blue';
    }
  };
  const buttonColor = getColor(color);

  const buttonSize = size ? size : 'small';
  const spinnerSize = () => {
    if (size === 'large') {
      return '24px';
    }

    return size === 'medium' ? '16px' : '14px';
  };
  const buttonDisabled = disabled || isLoading;

  if (outlineButton) {
    return (
      <button
        onClick={onClick}
        disabled={buttonDisabled}
        type={type}
        style={style}
        className={cx('fp-button', {
          [`fp-button-${buttonSize}`]: buttonSize,
          [`fp-button-${buttonColor === 'blue'? 'blue': 'white'}-outline`]: buttonColor,
        })}
      >
        {leftIcon && leftIcon}
        {isLoading ? <LoadingCircle spinnerSize={spinnerSize()} /> : buttonText}
        {rightIcon && rightIcon}
      </button>
    );
  }

  if (linkButton) {
    return (
      <button
        onClick={onClick}
        disabled={buttonDisabled}
        type={type}
        style={style}
        className={cx('fp-button', {
          [`fp-button-${buttonSize}`]: buttonSize,
          [`fp-button-link`]: linkButton,
        })}
      >
        {leftIcon && leftIcon}
        {isLoading ? <LoadingCircle spinnerSize={spinnerSize()} /> : buttonText}
        {rightIcon && rightIcon}
      </button>
    );
  }


  return (
    <button
      onClick={onClick}
      disabled={buttonDisabled}
      type={type}
      style={style}
      className={cx('fp-button', {
        [`fp-button-${buttonSize}`]: buttonSize,
        [`fp-button-${buttonColor}`]: buttonColor,
        [`fp-button-ghost-${buttonColor === 'white' ? 'white': 'neutrals'}`]: ghostButton,
      })}
    >
      {leftIcon && leftIcon}
      {isLoading ? <LoadingCircle spinnerSize={spinnerSize()} /> : buttonText}
      {rightIcon && rightIcon}
    </button>
  );
}

Button.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  type: PropTypes.string,
  style: PropTypes.object,
  outlineButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  linkButton: PropTypes.bool,
};

export default Button;
