export default {
  TRACKING: {
    key: 'TRACKING',
    title: 'Tracking',
    name: 'tracking',
    pathName: 'tracking',
    url: '/tracking',
    gaEventTrackerLabel: 'Link',
  },
  INSIGHT: {
    key: 'INSIGHT',
    title: 'Insights',
    name: 'insights',
    pathName: 'insightsnew',
    url: '/insightsnew',
    gaEventTrackerLabel: 'Link',
  },
  FREIGHT: {
    key: 'FREIGHT',
    title: 'Freight',
    name: 'freight',
    pathName: 'freight',
    url: '/freight',
    gaEventTrackerLabel: 'Link',
  },
  FLEET: {
    key: 'FLEET',
    title: 'Fleet',
    name: 'fleet',
    pathName: 'fleet',
    url: '/fleet/currentFleetStatus',
    gaEventTrackerLabel: 'Link',
  },
  MAINTENANCE: {
    key: 'MAINTENANCE',
    title: 'Maintenance',
    name: 'maintenance',
    pathName: 'maintenance',
    url: '/maintenance',
    gaEventTrackerLabel: 'Link',
  },
  ALERTS: {
    key: 'ALERTS',
    title: 'Alerts',
    name: 'alerts',
    pathName: 'alertconfiguration',
    url: '/alertconfiguration',
    gaEventTrackerLabel: 'Link',
  },
  PARTS: {
    key: 'PARTS',
    title: 'Parts',
    name: 'parts',
    pathName: 'parts',
    url: '/parts',
    gaEventTrackerLabel: 'Link',
  },
  HELP: {
    key: 'HELP',
    name: 'help',
    title: 'Help',
    pathName: 'help',
    url: '/help/faq',
    gaEventTrackerLabel: 'Link',
  },
};
