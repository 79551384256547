import {
  TOGGLE_TRACKING_HISTORY,
  RECEIVE_TRACKING_HISTORY_DATA,
  TOGGLE_TRACKING_HISTORY_DATA_LOADING,
  FETCH_TRACKING_HISTORY_DATA_FAIL,
  TRACKING_HISTORY_DAYS,
} from '../constants';

const initialState = {
  trackingHistoryToggled: false,
  trackingHistoryData: {},
  trackingHistoryDataLoading: false,
  error: false,
  days: 1,
};

const actionMappings = {
  [TOGGLE_TRACKING_HISTORY]: '_toggleTrackingHistory',
  [RECEIVE_TRACKING_HISTORY_DATA]: '_receiveTrackingHistoryData',
  [TOGGLE_TRACKING_HISTORY_DATA_LOADING]: '_toggleLoading',
  [FETCH_TRACKING_HISTORY_DATA_FAIL]: '_fetchDataFailure',
  [TRACKING_HISTORY_DAYS]: '_trackingHistoryDays',
};

const reducer = {
  _toggleTrackingHistory(state, { val }) {
    return {
      ...state,
      trackingHistoryToggled: val,
      days: 1,
      trackingHistoryData: {},
    };
  },

  _trackingHistoryDays(state, { val }) {
    return {
      ...state,
      days: val,
    };
  },

  _receiveTrackingHistoryData(state, { data }) {
    return {
      ...state,
      trackingHistoryData: data,
    };
  },

  _toggleLoading(state) {
    return {
      ...state,
      trackingHistoryDataLoading: !state.trackingHistoryDataLoading,
    };
  },

  _fetchDataFailure(state) {
    return {
      ...state,
      error: true,
    };
  },
};

const trackingHistory = (state = initialState, action) => {
  const method = actionMappings[action.type];
  return method ? reducer[method].call(null, state, action) : state;
};

export default trackingHistory;
