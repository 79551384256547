export const FAULT_LEVELS = {
  ABS: {
    id: '1',
    faultDesc: 'ABS Fault',
    faultLevelId: 1,
  },
  COMPONENT: {
    id: '2',
    faultDesc: 'Component Fault',
    faultLevelId: 2,
  },
};

export const ABS_FAULT_FILTERS = {
  ALL: {
    label: 'All',
    value: 'All',
  },
  ABS: {
    label: 'ABS',
    value: 'ABS',
  },
  COMPONENT: {
    label: 'Component',
    value: 'Component',
  },
};

export const ABS_SEVERITY_LEVELS = {
  GOOD: {
    key: 'GOOD',
    id: 1,
    level: 'Good',
  },
  CAUTION: {
    key: 'CAUTION',
    id: 2,
    level: 'Caution',
  },
  CRITICAL: {
    key: 'CRITICAL',
    id: 3,
    level: 'Critical',
  },
};

export const hasCriticalAbsSeverityLevel = id => id === ABS_SEVERITY_LEVELS.CRITICAL.id;

export const hasCautionAbsSeverityLevel = id => id === ABS_SEVERITY_LEVELS.CAUTION.id;
