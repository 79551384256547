export const mapTrailersQuery = ` {
  fleetStatusPage(input: {
    pageNumber: 1,
    pageSize: null,
    sort: null,
    filters: [],
    extMfgrSerialNums: [],
    customerIds: [],
    vehicleSensorBoxActive: true,
    recordedAfterVehicleSensorBoxPaired: true,
    customerVehicleActive: true,
    useSensorBoxCaches: true,
    activeFaults: true
  }) {
    trailers {
      vin
      vehicleTypeId
      unitId
      tireInflationStatus
      timeUntetheredDays
      tetheredStatus
      loadStatus
      lng
      lat
      gpsHeading
      gpsOdometer
      deviceTypeId
      customerId
      absOdometer
      absFaultSeverityLevel
      installedSensorTypeIds
      connectivityStatus
      gatewayBatteryStatus
			trailerBatteryStatus
			baseProductType
      goProductType
      isThermoking
    }
  }
}
 `;
